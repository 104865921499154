<template>
	<div>
		<v-textarea v-model="selected"></v-textarea>
		<slot
			name="pollResults"
		></slot>

	</div>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
import Vuex from "vuex";
// Renders the RADIO TILES question type and emits the selected answer
// @group Survey
export default {
	name: "RadioTiles",
	props: {
		// the ID of the question document
		id: { type: String },
		// The current value of the question response
		data: { type: [Object, Array, String, Number] },
		// Whether the question should be disabled (i.e. answer can not be changed )
		disabled: { type: Boolean },
		// Whether this question allows multiple responses
		multiple: { type: Boolean, default: false },
	},
	data: () => {
		return {
			selected: false,
		};
	},
	computed: {
		...Vuex.mapState({
			answerOptions(state) {
				return state.questions.data[this.id].answerOptions || {};
			},
		}),
		question() {
			return this.$store.state.questions.data[this.id];
		},
		options() {
			return this.mwutilities.getPositions(this.answerOptions);
		},
	},
	watch: {
		selected() {
			this.$emit("update", this.selected);
		},
		data: {
			immediate: true,
			handler(data) {
				this.selected = data;
			},
		},
	},
	methods: {
		// @vuese
		// Emits an error message
		warn() {
			// fired when a user tries to edit a disabled question
			this.$emit("error");
		},
	},
};
//
</script>
"
